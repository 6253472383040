import React, { useEffect } from "react"
import PropTypes from "prop-types"

const FormBlock = ({ title, className }) => {
  useEffect(() => {
    document.readyState === "complete" &&
      typeof window.loadPromotigo === "function" &&
      window.loadPromotigo()
  }, [])

  return (
    <section id="your-story" className={`container form-block ${className}`}>
      <div className="form-block__copy">
        <h2 className="form-block__title">{title}</h2>
      </div>
      <div className="form-block__sub-titles">
        <h3>Your Details</h3>
        <h3>Tell us your story</h3>
      </div>
      <div className="form-block__form">
        <div id="promotigoForm">Loading...</div>
      </div>
    </section>
  )
}

FormBlock.defaultProps = {
  className: "tout",
  title: "",
}

FormBlock.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default FormBlock
