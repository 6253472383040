import React from "react"
import PropTypes from "prop-types"
import Button from "./core/Button"
import Image from "./core/Image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import background from "../assets/images/rectangle-5.svg"

const Tout1 = ({
  title,
  subTitle,
  text,
  ctaLink,
  ctaText,
  image1,
  image2,
  image3,
  className,
}) => {
  return (
    <section className={`container tout1 ${className}`}>
      <div
        className="tout1__images"
        data-sal="fade"
        data-sal-duration="1000"
        data-sal-easing="ease-out"
      >
        <div className="tout1__images__container">
          <Image className="tout1__image1" data={image1} alt="" />
          <Image className="tout1__image2" data={image2} alt="" />
          <Image className="tout1__image3" data={image3} alt="" />
        </div>
      </div>
      <div
        className="tout1__copy"
        data-sal="fade"
        data-sal-duration="1500"
        data-sal-easing="ease"
      >
        <div
          className="tout1__copy__inner"
          style={{ backgroundImage: `url(${background})` }}
        >
          <h2 className="tout1__title">
            {title}
            <span className="tout1__subtitle">{subTitle}</span>
          </h2>
          <div className="tout1__text">
            <MDXRenderer>{text}</MDXRenderer>
          </div>
          <Button
            colour="dark"
            text={ctaText}
            type="link"
            link={ctaLink}
            gatsbyLink={true}
            padding={"narrow"}
          />
        </div>
      </div>
    </section>
  )
}

Tout1.defaultProps = {
  className: "tout",
  title: "",
  text: "",
}

Tout1.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.node,
  image1: PropTypes.object,
  image2: PropTypes.object,
}

export default Tout1
