import React from "react"
import PropTypes from "prop-types"
import Image from "./core/Image"
import sunbackground from "../assets/images/sun2.svg"

const Tout2 = ({ title, text, image1, className }) => {
  return (
    <section className={`container tout2 ${className}`}>
      <div
        className="tout2__copy"
        data-sal="fade"
        data-sal-duration="1000"
        data-sal-easing="ease"
      >
        <h2 className="tout2__title">{title}</h2>
        <p className="tout2__text">{text}</p>
      </div>

      <div
        className="tout2__image"
        data-sal="fade"
        data-sal-delay="500"
        data-sal-duration="1000"
        data-sal-easing="ease"
      >
        <Image className="tout2__img" data={image1} alt="" />
        <img
          src={sunbackground}
          className="tout2__image__background"
          aria-hidden="true"
          alt="background sun"
        />
      </div>
    </section>
  )
}

Tout2.defaultProps = {
  className: "tout2",
}

Tout2.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.node,
  image1: PropTypes.object,
  image2: PropTypes.object,
  className: PropTypes.string.isRequired,
}

export default Tout2
