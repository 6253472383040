import React from "react"
import PropTypes from "prop-types"
import Button from "./core/Button"
import background from "../assets/images/gesture7.png"

const CareBlock = ({ title, text, ctaLink, ctaText }) => {
  return (
    <section
      className="container care"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div
        className="care__content"
        data-sal="fade"
        data-sal-duration="1000"
        data-sal-easing="ease-out"
      >
        <h2>{title}</h2>
        <p>{text}</p>
        <Button
          colour="orange"
          text={ctaText}
          type="link"
          link={ctaLink}
          gatsbyLink={false}
          padding={"standard"}
        />
      </div>
    </section>
  )
}

CareBlock.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.node,
}

export default CareBlock
