import React from "react"
import Image from "./core/Image"
import { Link } from "gatsby"

export default function Hero({ backgroundImage, image1, image2, image3 }) {
  return (
    <section className="container hero">
      <Link to="/">
        <Image
          className="hero__image hero__image--background"
          data={backgroundImage}
          alt=""
          objectFit="contain"
        />
      </Link>
      <div className="hero__content">
        <h1>
          Bringing<span>Caring Minds</span> Together
        </h1>
        <Image
          className="hero__image hero__image--1"
          data={image1}
          alt=""
          objectFit="contain"
        />
        <Image
          className="hero__image hero__image--2"
          data={image2}
          alt=""
          objectFit="contain"
        />
        <Image
          className="hero__image hero__image--3"
          data={image3}
          alt=""
          objectFit="contain"
        />
      </div>
    </section>
  )
}
