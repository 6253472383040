import React from "react"
import PropTypes from "prop-types"
import Button from "./core/Button"

const CallToAction = ({ text, ctaLink, ctaText }) => {
  return (
    <section className="container cta">
      <div
        className="cta__title"
        data-sal="fade"
        data-sal-duration="1000"
        data-sal-easing="ease-out"
      >
        <h2>{text}</h2>
      </div>
      <div
        className="cta__btn"
        data-sal="fade"
        data-sal-duration="1500"
        data-sal-easing="ease"
      >
        <Button
          colour="dark"
          text={ctaText}
          type="link"
          link={ctaLink}
          gatsbyLink={false}
          padding={"wide"}
        />
      </div>
    </section>
  )
}

CallToAction.defaultProps = {
  className: "tout",
  title: "",
  text: "",
}

CallToAction.propTypes = {
  text: PropTypes.node,
}

export default CallToAction
