import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/SEO"
import Hero from "../components/Hero"
import Tout1 from "../components/Tout1"
import FormBlock from "../components/FormBlock"
import Tout2 from "../components/Tout2"
import CallToAction from "../components/CallToAction"
import CareBlock from "../components/CareBlock"
import CarePrizesBlock from "../components/CarePrizesBlock"

const Homepage = () => {
  const data = useStaticQuery(graphql`
    {
      page: mdx(fileAbsolutePath: { regex: "/content/index/" }) {
        body
        frontmatter {
          path
          title
          hero {
            backgroundImage
            image1
            image2
            image3
          }
          tout1 {
            title
            subTitle
            text
            ctaLink
            ctaText
            className
            image1
            image2
            image3
          }
          formBlock {
            title
            className
          }
          tout2 {
            title
            text
            className
            image1
            image2
          }
          callToAction {
            title
            ctaLink
            ctaText
          }
          careBlock {
            title
            text
            ctaLink
            ctaText
          }
          giveawayBlock {
            title
            subText
            subTitle
            subTitleAlt
            listItems
            ctaLink
            ctaText
          }
        }
      }
      images: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "assets/images" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              id
              fluid {
                originalName
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  `)
  const {
    hero,
    tout1,
    tout2,
    callToAction,
    careBlock,
    giveawayBlock,
    formBlock,
  } = data.page.frontmatter

  const images = data.images.edges.map(data => data.node.childImageSharp.fluid)
  const getImage = name =>
    images.find(({ originalName }) => originalName === name)
  return (
    <>
      <SEO title="Home" />

      <Hero
        backgroundImage={getImage(hero.backgroundImage)}
        image1={getImage(hero.image1)}
        image2={getImage(hero.image2)}
        image3={getImage(hero.image3)}
      />

      <Tout1
        {...tout1}
        image1={getImage(tout1.image1)}
        image2={getImage(tout1.image2)}
        image3={getImage(tout1.image3)}
        text={data.page.body}
        background={getImage(tout1.background)}
      />
      <CallToAction
        text={callToAction.title}
        ctaLink={callToAction.ctaLink}
        ctaText={callToAction.ctaText}
      />
      <CareBlock
        title={careBlock.title}
        text={careBlock.text}
        ctaLink={careBlock.ctaLink}
        ctaText={careBlock.ctaText}
      />
      <CarePrizesBlock
        // title={prizesBlock.title}
        // subText={prizesBlock.subText}
        // subTitle={prizesBlock.subTitle}
        // subTitleAlt={prizesBlock.subTitleAlt}
        // listItems={}
        // ctaLink={prizesBlock.ctaLink}
        // ctaText={prizesBlock.ctaText}
        {...giveawayBlock}
      />
      <FormBlock {...formBlock} />
      <Tout2
        {...tout2}
        image1={getImage(tout2.image1)}
        image2={getImage(tout2.image2)}
      />
    </>
  )
}

export default Homepage
